import styled from 'styled-components';
import { media } from '../utils/mixins';

const SocialLinkContainer = styled.div`
    ${media.phone`
    justify-content: center;  
    `}
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

export { SocialLinkContainer };
