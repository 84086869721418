import styled from 'styled-components';
import { media } from '../utils/mixins';

export const TextBox = styled.div`
    ${media.stayInTouchWrap`
    flex-basis: 100%;
    `}
    ${media.phone`
    text-align: center; 
    `}
    margin-top: 1%;
    margin-bottom: 1%;
    ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    align-content: space-between;
`;
