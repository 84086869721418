import styled from 'styled-components';

export const RedCircleBorder = styled.a`
    display: flex;
    align-items: center;
    flex-basis: 51px;
    margin-left: 5%;
    height: 51px;
    border: 0.1rem solid ${(props) => props.theme.colors.primary};
    border-radius: 999px;
    color: ${(props) => props.theme.colors.primary};
    justify-content: center;
`;
