import React from 'react';
import { SocialLinkContainer } from '../components/SocialLinkContainer';
import { RedCircleBorder } from '../components/RedCircleBorder.jsx';
import { OuterContainer } from '../components/OuterContainer.jsx';
import { ImageContainer } from '../components/ImageContainer.jsx';
import { TextBox } from '../components/TextBox.jsx';
import { ButtonStyle } from '../components/Button.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { SectionTemplate } from '../components';
import { Heading, Paragraph } from '@cheapreats/react-ui';
import { Layout } from '../components/Layout';
import styled from 'styled-components';

import {
    FaInstagram,
    FaTwitter,
    FaFacebookF,
    FaLocationArrow,
} from 'react-icons/fa';

// TODO: Andrew Button
const NewsLetterPage = () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "paperplanes.png" }) {
                childImageSharp {
                    fluid(maxHeight: 800, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <Layout title="NewsLetter">
            <SectionTemplate
                headingText="The Future is Here!"
                paragraphText="Sign up for our news letter to be the very first to hear
                about all things CheaprEats, get exclusive deals and much
                more!"
                textComponent={
                    <Container flexDirection="row">
                        <Input
                            type="text"
                            placeholder="Enter your email here"
                        />
                        <Button>
                            <Arrow />
                        </Button>
                    </Container>
                }
                imageContent={
                    <ImageContainer>
                        <Img fluid={data.file.childImageSharp.fluid} />
                    </ImageContainer>
                }
            />
        </Layout>
    );
};

const icons = [<FaTwitter />, <FaFacebookF />, <FaInstagram />];

const Container = styled.div`
    width: 100%;
    height: 20px;
`;
const Input = styled.input`
    height: 100%;
    border: none;
    padding: 1rem;
    background-color: ${(props) => props.theme.colors.secondaryBackground};
    font-family: ${(props) => props.theme.fonts.heading};
    ::placeholder {
        color: ${(props) => props.theme.colors.primary};
        opacity: 1;
    }
`;

const Button = styled(ButtonStyle)`
    height: 100%;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 5%;
`;

const Arrow = styled(FaLocationArrow)`
    color: ${(props) => props.theme.colors.background};
    transform: rotate(45deg);
`;

export default NewsLetterPage;
