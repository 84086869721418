import styled from 'styled-components';

export const OuterContainer = styled.div`
    display: flex;
    ${({ flexDirection }) =>
        flexDirection ? `flex-direction: ${flexDirection};` : ''}
    ${({ paddingBottom }) =>
        paddingBottom ? `padding-bottom: ${paddingBottom};` : ''}
    ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;
